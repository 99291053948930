<template>
  <div>
    <b-button-group style="width: 100%" v-if="is_read_only">
      <b-button variant="warning" :to="'/edit-requisition/'+requisition_id" style="float: none; width: 25%"
                title="Edit Requisition">
        <b-icon icon="pencil-square"></b-icon>
      </b-button>
      <b-button variant="success" @click="downloadRequisition()" style="float: none; width: 25%"
                title="Download Requisition PDF">
        <b-icon icon="download" ></b-icon>
      </b-button>
    </b-button-group>
    <h2 v-if="is_read_only" style="text-align: center">{{form.title}}</h2>
    <b-form-input v-else placeholder="Requisition Title" v-model="form.title" :plaintext="is_read_only" size="lg">
    </b-form-input>
    <p>Created on: {{datetime_created.toLocaleString()}} by {{ author.full_name}}
      <span v-if="datetime_last_changed > datetime_created">
        <em>
          | Last edit: {{datetime_last_changed.toLocaleString()}} by {{ last_changer.full_name}}
        </em>
      </span>
    </p>
    <hr/>
    <b-card>
      <b-card-header>
        <b-card-title>Vendor Information
          <b-button @click="loadVendor(null)" v-show="!is_read_only" style="float: right"
                    size="sm" variant="primary">
            Use Existing Vendor
          </b-button>
        </b-card-title>
      </b-card-header>

      <b-card-body>
        <b-form-group id="vendor-info-input-group" >
          <b-form-group label="Vendor Name" label-class="font-weight-bold pt-0">
            <b-input-group>
              <b-form-input id="vendor-name" v-model="form.vendor.name"
                            :plaintext="is_read_only"> </b-form-input>
            </b-input-group>
          </b-form-group>
          <b-modal id="load-vendor-modal" ok-only ok-title="close" title="Select a Vendor">
            <b-input placeholder="filter vendor" v-model="vendor_filter"></b-input>
            <b-row v-for="vendor in vendors_filtered" :key="vendor.name" style="margin-bottom: 5px;" no-gutters>
              <b-col cols="10">
                <b-button block variant="success" @click="loadVendor(vendor)">{{vendor.name}}</b-button>
              </b-col>
              <b-col cols="2">
                <b-button pill variant="danger" @click="deleteVendor(vendor)"><b-icon icon="x"></b-icon></b-button>
              </b-col>
            </b-row>
          </b-modal>
          </b-form-group>
          <b-form-group label="Street Address" label-class="font-weight-bold pt-0">
            <b-form-input id="street-address" v-model="form.vendor.street_address"
                          :plaintext="is_read_only"></b-form-input>
          </b-form-group>
          <b-row>
            <b-col cols="12" md="4">
              <b-form-group label="City" label-class="font-weight-bold pt-0">
                <b-form-input id="city" v-model="form.vendor.city" :plaintext="is_read_only"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-group label="State" label-class="font-weight-bold pt-0">
                <b-form-input id="state" v-model="form.vendor.state" :plaintext="is_read_only"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-group label="Zip Code" label-class="font-weight-bold pt-0">
                <b-form-input id="zip" v-model="form.vendor.zip" :plaintext="is_read_only"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="6">
              <b-form-group label="Company Website" label-class="font-weight-bold pt-0">
              <b-form-input id="url" v-model="form.vendor.url" :plaintext="is_read_only"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="6">
              <b-form-group label="Company Phone" label-class="font-weight-bold pt-0">
                <b-form-input id="company-phone" v-model="form.vendor.phone" :plaintext="is_read_only"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="6">
              <b-form-group label="Company Fax" label-class="font-weight-bold pt-0">
                <b-form-input id="company-fax" v-model="form.vendor.fax" :plaintext="is_read_only"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <hr/>
          <h4>Vendor Contact</h4>
          <b-row>
            <b-col cols="12" md="4">
              <b-form-group label="Contact Name" label-class="font-weight-bold pt-0">
                <b-form-input id="contact-name" v-model="form.vendor.contact_name" :plaintext="is_read_only"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-group label="Contact Phone" label-class="font-weight-bold pt-0">
                <b-form-input id="contact-phone" v-model="form.vendor.contact_phone" :plaintext="is_read_only"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="12" md="4">
              <b-form-group label="Contact Email" label-class="font-weight-bold pt-0">
                <b-form-input id="contact-email" v-model="form.vendor.contact_email" :plaintext="is_read_only"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
        <div style="text-align: right">
          <b-button variant="primary" @click="saveVendor" v-show="!is_read_only">Save Vendor</b-button>
        </div>
      </b-card-body>
    </b-card>
    <b-card>
      <b-card-header>
        <b-card-title>
          Order Information
        </b-card-title>
      </b-card-header>
      <b-card-body>
        <b-form-group label="Research Purpose" label-class="font-weight-bold pt-0">
          <b-form-textarea placeholder="How is purchase used for this research project?" v-model="form.purpose" :plaintext="is_read_only">
          </b-form-textarea>
        </b-form-group>
        <b-form-group label="Required Delivery Date" label-class="font-weight-bold pt-0">
          <b-form-datepicker v-model="form.delivery_date" v-if="!is_read_only"></b-form-datepicker>
          <p v-else-if="form.delivery_date !== null">{{form.delivery_date.toLocaleString()}}</p>
          <p v-else>N/A</p>
        </b-form-group>
        <b-row>
          <b-col cols="12" md="6">
            <b-form-group label="Submitter" label-class="font-weight-bold pt-0">
              <b-form-select v-model="form.submitter" v-if="!is_read_only">
                <b-form-select-option :value="null">--Choose Submitter--</b-form-select-option>
                <b-form-select-option v-for="user in active_users" :key="user.username" :value="user">{{user.full_name}}</b-form-select-option>
              </b-form-select>
              <p v-else>{{form.submitter !== null ? form.submitter.full_name : "N/A"}}</p>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
          <b-form-group label="Supervisor" label-class="font-weight-bold pt-0">
            <b-form-select v-model="form.supervisor" v-if="!is_read_only">
              <b-form-select-option :value="null">--Choose Supervisor--</b-form-select-option>
              <b-form-select-option v-for="user in active_users" :key="user.username" :value="user">{{user.full_name}}</b-form-select-option>
            </b-form-select>
            <p v-else>{{form.supervisor !== null ? form.supervisor.full_name : "N/A"}}</p>
          </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="6">
            <b-form-group label="Cost Object" label-class="font-weight-bold pt-0">
              <div v-if="!is_read_only">
                <b-form-input list="co-list-id" v-model="form.cost_object"></b-form-input>
                <datalist id="co-list-id">
                  <option v-for="co in active_cost_objects" :key="co.id">{{co.id}} ({{co.description}})</option>
                </datalist>
              </div>
              <p v-else>{{form.cost_object !== null ? form.cost_object : "N/A"}}</p>
            </b-form-group>
          </b-col>
        </b-row>
        <b-form-group label="Special Instructions" label-class="font-weight-bold pt-0">
          <b-form-textarea v-model="form.special_instructions" placeholder="Put any special ordering instructions here." :plaintext="is_read_only">

          </b-form-textarea>
        </b-form-group>
        <hr/>
        <b-row>
          <b-col cols="12" md="6">
            <b-form-group label="Date Submitted" label-class="font-weight-bold pt-0">
              <b-form-datepicker v-model="form.date_submitted" v-if="!is_read_only" reset-button ></b-form-datepicker>
              <p v-else-if="form.date_submitted !== null">{{form.date_submitted.toLocaleString()}}</p>
              <p v-else>N/A</p>
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group label="Date Received" label-class="font-weight-bold pt-0">
              <b-form-datepicker v-model="form.date_received" v-if="!is_read_only" reset-button ></b-form-datepicker>
              <p v-else-if="form.date_received !== null">{{form.date_received.toLocaleString()}}</p>
              <p v-else>N/A</p>
            </b-form-group>
          </b-col>
        </b-row>
        <hr/>
        <b-row v-if="!is_read_only" style="margin-bottom: 5px">
          <b-col cols="12" md="6">
            <b-form-group label="Supporting Material" label-class="font-weight-bold pt-0">
            <b-form-file
                v-model="staged_file"
                placeholder="Choose a file or drop it here..."
                drop-placeholder="Drop file here..."
                @input="uploadFiles([staged_file])"
            ></b-form-file>
            </b-form-group>
          </b-col>
        </b-row>
        <b-card  no-body header="Attached Documents" v-show="resources.length > 0">
          <b-list-group flush>
            <b-list-group-item v-for="(resource, index) in resources" :key="resource.id">
              <b-link v-if="!is_read_only" v-on:click="removeResource(resource)"><b-icon icon="x-circle-fill" scale="1" variant="danger"></b-icon>
              </b-link>
              [{{index+1}}]
              <b-link :href="resource.url" target="_blank" rel="noopener noreferrer">
              {{resource.original_filename}} ({{Math.ceil(10*resource.size_bytes / 1024)/10}} kB)
              </b-link>
            </b-list-group-item>
          </b-list-group>
        </b-card>
        <hr/>
        <table class="table table-striped" v-if="items.length > 0">
          <thead>
          <tr>
            <th>Part Number</th>
            <th class="d-none d-lg-table-cell">Short Description</th>
            <th>Quantity</th>
            <th>Unit Price</th>
            <th>Total Price</th>
            <th v-if="!is_read_only"></th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="item in items" :key="item.part_number">
            <td v-if="item.url"><a :href="item.url" target="_blank">{{item.part_number}}</a></td>
            <td v-else>{{item.part_number}}</td>
            <td class="d-none d-lg-table-cell">{{item.description}}</td>
            <td>{{item.quantity}}</td>
            <td>{{item.unit_price}}</td>
            <td>{{totalPrice(item.quantity, item.unit_price)}}</td>
            <td v-if="!is_read_only">
              <b-button-group style="width: 100%">
                <b-button size="sm" variant="warning" @click="editItem(item)"><b-icon icon="pencil"></b-icon></b-button>
                <b-button size="sm" variant="danger" @click="removeItem(item)"><b-icon icon="trash"></b-icon></b-button>
              </b-button-group>
            </td>
          </tr>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td><b>Grand Total:</b> </td>
            <td>{{grandTotal()}}</td>
            <td v-if="!is_read_only"></td>
          </tr>
          </tbody>
        </table>
        <b-button variant="success" @click="newItem()" v-if="!is_read_only">Add Item</b-button>
        <b-modal id="item-edit-modal" :ok-disabled="!edit_form_ok" :ok-title="edit_form_is_new ? 'Create' : 'Update'" ok-variant="success" @ok="finishEdit">
          <b-form-group>
            <b-form-input v-model="edit_form.part_number" type="text" placeholder="Part Number"></b-form-input>
          </b-form-group>
          <b-form-group>
            <b-form-textarea v-model="edit_form.description" placeholder="Short Description"></b-form-textarea>
          </b-form-group>
          <b-form-group>
            <b-form-input v-model="edit_form.url" placeholder="URL (Optional)"></b-form-input>
          </b-form-group>
          <b-form-group>
            <b-form-input v-model="edit_form.quantity" type="number" placeholder="Quantity"></b-form-input>
          </b-form-group>
          <b-form-group>
            <b-input-group prepend="$">
              <b-form-input v-model="edit_form.unit_price" align="right" type="text" placeholder="Unit Price"></b-form-input>
            </b-input-group>
          </b-form-group>
        </b-modal>
      </b-card-body>
    </b-card>
    <b-button v-if="!is_read_only" block variant="primary" @click="saveRequisition">Save Requisition</b-button>
  </div>
</template>

<script>
import Decimal from "decimal.js";
import http from "../http-common"
import {toInteger} from "lodash";
import FileDownload from "js-file-download";
export default {
  name: "EditRequisition",
  props: [
     "active_users",
     "cost_objects",
     "loggedIn",
  ],
  data: function () {
    return {
      requisition_id: null,
      datetime_created: new Date(),
      datetime_last_changed: null,
      author: {
        full_name: "",
      },
      last_changer: {
        full_name: "",
      },
      form: {
        title: "",
        vendor: {
          name: "",
          street_address: "",
          city: "",
          state: "",
          zip: "",
          url: "",
          phone: "",
          fax: "",
          contact_name: "",
          contact_phone: "",
          contact_email: "",
        },
        purpose: "",
        delivery_date: null,
        submitter: null,
        supervisor: null,
        cost_object: null,
        special_instructions: "",
        date_submitted: null,
        date_received: null,
      },
      staged_file: null,
      resources: [],
      edit_form: {
        idx: -1,
        part_number: "",
        description: "",
        url: "",
        quantity: 1,
        unit_price: null,
      },
      is_read_only: true,
      edit_form_is_new: true,
      edit_form_errors: [],
      items: [
      ],
      vendors: [
      ],
      vendor_filter: "",
    };
  },
  computed: {
    edit_form_ok() {
      if (this.edit_form.part_number.trim() === "") {
        return false;
      }
      if (this.edit_form.description.trim() === "") {
        return false;
      }
      try {
        new Decimal(this.edit_form.quantity)
      } catch {
        return false
      }
      try {
        let unit_price = new Decimal(this.edit_form.unit_price);
        if (unit_price.lessThan(0)){
          return false;
        }
      } catch {
        return false
      }
      try {
        let quantity = new Decimal(this.edit_form.quantity);
        if (quantity.lessThan(0)){
          return false;
        }
      } catch {
        return false
      }
      return true;
    },
    active_cost_objects() {
      let active_cost_objects = [];
      this.cost_objects.forEach((co) => {
        if (co.active) {
          active_cost_objects.push(co);
        }
      });
      return active_cost_objects;
    },
    vendors_filtered() {
      if (this.vendor_filter === "") {
        return this.vendors;
      } else {
        let f = [];
        this.vendors.forEach((v) => {
          if (v.name.toLowerCase().includes(this.vendor_filter.toLowerCase())) f.push(v);
        });
        return f;
      }
    }
  },
  methods: {
    removeItem(item) {
      this.items.splice(item.idx,1)
      for(let idx=0; idx<this.items.length; idx++){
        this.items[idx].idx = idx
      }
    },
    editItem(item) {
      this.edit_form.idx = item.idx;
      this.edit_form.part_number = item.part_number;
      this.edit_form.description = item.description;
      this.edit_form.url = item.url || "";
      this.edit_form.quantity = item.quantity;
      this.edit_form.unit_price = item.unit_price;
      this.edit_form_is_new = false;
      this.$bvModal.show('item-edit-modal');
    },
    newItem() {
      this.edit_form.idx = -1;
      this.edit_form.part_number = "";
      this.edit_form.description = "";
      this.edit_form.url = "";
      this.edit_form.quantity = "";
      this.edit_form.unit_price = "";
      this.edit_form_is_new = true;
      this.$bvModal.show('item-edit-modal');
    },
    finishEdit() {
      if (this.edit_form.url && !this.edit_form.url.startsWith('http://') && !this.edit_form.url.startsWith('https://')) {
        this.edit_form.url = "https://"+this.edit_form.url;
      }
      if (this.edit_form.idx < 0) {
        this.items.push({
          idx: this.items.length,
          part_number: this.edit_form.part_number,
          description: this.edit_form.description,
          url: this.edit_form.url,
          quantity: parseFloat(this.edit_form.quantity),
          unit_price: parseFloat(this.edit_form.unit_price),
        });
      } else {
        this.$set(this.items, this.edit_form.idx, {
          idx: this.edit_form.idx,
          part_number: this.edit_form.part_number,
          description: this.edit_form.description,
          url: this.edit_form.url,
          quantity: parseFloat(this.edit_form.quantity),
          unit_price: parseFloat(this.edit_form.unit_price),
        });
      }
    },
    totalPrice(quantity, unit_price) {
      const q = new Decimal(quantity);
      const p = new Decimal(unit_price);
      const total = q.times(p);
      return total.toFixed(3);
    },
    grandTotal() {
      let sum = new Decimal(0);
      this.items.forEach((item) =>
          sum = sum.add((new Decimal(item.quantity)).times(new Decimal(item.unit_price)))
      );
      return sum.toFixed(2);
    },
    saveVendor() {
      http.put("/vendor/",
          {
            name: this.form.vendor.name,
            street_address: this.form.vendor.street_address,
            city: this.form.vendor.city,
            state: this.form.vendor.state,
            zip: this.form.vendor.zip,
            phone: this.form.vendor.phone,
            fax: this.form.vendor.fax,
            url: this.form.vendor.url,
            contact_name: this.form.vendor.contact_name,
            contact_phone: this.form.vendor.contact_phone,
            contact_email: this.form.vendor.contact_email,
          })
      .then(() => {
        this.$emit('show-alert', {
          message: `Vendor ${this.form.vendor.name} saved successfully!`,
          type: 'success',
        });
      })
      .catch((e) => {
        http.std_error_handler(this, e);
      });
    },
    loadVendor(vendor) {
      if (vendor === null) {
        http.get("/vendor/")
            .then((resp) => {
              this.vendors = resp.data;
              this.$bvModal.show('load-vendor-modal');
            })
            .catch((e) => {
              http.std_error_handler(this, e);
            });
      } else {
        this.form.vendor = vendor;
        this.$bvModal.hide('load-vendor-modal');
      }
    },
    deleteVendor(vendor) {
      http.delete(`/vendor/${encodeURIComponent(vendor.name)}`)
      .then(() => {
        this.loadVendor(null);
      })
      .catch((e) => {
        http.std_error_handler(this, e);
      });
    },
    loadRequisition(req) {
      const load = (requisition) => {
        this.form.title = requisition.title;
        this.datetime_created = new Date(requisition.datetime_created);
        this.datetime_last_changed = new Date(requisition.datetime_last_changed);
        this.author = requisition.author;
        this.last_changer = requisition.last_changer;
        this.form.vendor = requisition.vendor;
        this.form.purpose = requisition.extra.purpose || null;
        this.items = requisition.items;
        this.form.cost_object = requisition.extra.cost_object || null;
        this.form.special_instructions = requisition.extra.special_instructions || null;
        this.form.delivery_date = requisition.extra.delivery_date || null;
        this.form.date_submitted = requisition.date_submitted;
        this.form.date_received = requisition.date_received;
        this.form.submitter = null;
        this.form.supervisor = null;
        for(let idx in this.active_users) {
          const user = this.active_users[idx];
          if (user.username === requisition.extra.submitter_username) {
            this.form.submitter = user;
          }
          if (user.username === requisition.extra.supervisor_username) {
            this.form.supervisor = user;
          }
        }
        this.resources = requisition.resources;
      }

      if ((req || null) !== null) {
        load(req);
      } else {
        let loader = this.$loading.show()
        http.get('/requisition/'+this.requisition_id)
            .then((response) => {
              load(response.data);
            })
            .catch((err) => {
              if (http.std_error_handler(this, err)) {
                if (err.response.status === 404) {
                  this.$emit('show-alert', {
                    message: `Requisition #${this.requisition_id} not found.`,
                    type: 'warning',
                  });
                  this.$router.push('/requisitions');
                }
              }
            })
            .finally(() => {
              loader.hide();
            });
      }
    },
    downloadRequisition() {
      let loader = this.$loading.show()
      http.get("/requisition/download/"+this.requisition_id,
          {responseType: 'blob'})
          .then((resp) => {
            FileDownload(resp.data, `${this.form.title}.pdf`);
          })
          .catch((err) => {
            http.std_error_handler(this, err);
          })
          .finally(() => {
            loader.hide()
          })
    },
    saveRequisition() {
      let loader = this.$loading.show()
      http.put('/requisition/'+this.requisition_id,{
        title: this.form.title,
        vendor: this.form.vendor,
        purpose: this.form.purpose,
        delivery_date: this.form.delivery_date === "" ? null : this.form.delivery_date,
        items: this.items,
        submitter_username: this.form.submitter === null ? null : this.form.submitter.username,
        supervisor_username: this.form.supervisor === null ? null : this.form.supervisor.username,
        cost_object: this.form.cost_object,
        status: this.form.status,
        special_instructions: this.form.special_instructions,
        date_submitted: this.form.date_submitted === "" ? null : this.form.date_submitted,
        date_received: this.form.date_received === "" ? null : this.form.date_received,
      })
      .then(() => {
        this.$router.push('/requisitions');
      })
      .catch((err) => {
        http.std_error_handler(this, err);
      })
      .finally(() => {
        loader.hide();
      });
    },
    updateResources() {
      let loader = this.$loading.show()
      http.get('/requisition/'+this.requisition_id)
          .then((response) => {
            this.resources = response.data.resources;
          })
          .catch((err) => {
            http.std_error_handler(this, err);
          })
          .finally(() => {
            loader.hide();
          });
    },
    uploadFiles(files) {
      const loader = this.$loading.show();
      http.upload_files(files,
          (res_id) => {
            return http.put(`requisition/${this.requisition_id}/resource`,
                {}, {params: {resource_id: res_id}})
                .catch((err) => {
                  http.std_error_handler(this, err);
                });
          },
          () => {
            this.updateResources()
            this.staged_file = null;
            loader.hide();
          }
      );
    },
    removeResource(res) {
      http.delete(`/requisition/${this.requisition_id}/resource`,
        {params: {resource_id: res.id}})
      .then(() => {
        this.updateResources();
      })
      .catch((err) => {
          http.std_error_handler(this, err);
      });
    },
  },
  beforeMount() {
    if (!this.loggedIn) {
      this.$router.push('/login');
    }
  },
  mounted() {
    this.requisition_id = toInteger(this.$route.params.id);
    this.is_read_only = this.$route.name.match('edit-requisition') === null;
    this.$emit('sync-users', () => {
      this.loadRequisition()
    });
    this.$emit('sync-cost-objects');
  },
}
</script>

<style scoped>
.my-input-group-text {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

</style>